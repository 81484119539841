/* eslint-disable no-param-reassign */
exports.onRouteUpdate = () => {
  const loader = document.getElementById('loader');
  const mainVisual = document.getElementById('main_visual');

  const randomPool = [];

  const randomAnime = () => {
    const rndDelay = Math.floor(Math.random() * 4) + 1;
    let rndId = Math.floor(Math.random() * 26) + 1;

    while (randomPool.includes(rndId)) {
      rndId = Math.floor(Math.random() * 26) + 1;
    }

    randomPool.push(rndId);

    const moveData = 'fadeUp'; // アニメーション名＝CSSのクラス名を指定
    const removeData = 'fadeOut'; // アニメーション名＝CSSのクラス名を指定
    const randomItemId = `balloon${rndId}`;
    const randomItem = document.getElementById(randomItemId);

    if (randomItem) {
      randomItem.classList.add(moveData, `anim-delay${rndDelay}`); // アニメーションのクラスを追加

      setTimeout(() => {
        randomItem.classList.add(removeData);
        randomItem.classList.remove(moveData);

        setTimeout(() => {
          randomItem.classList.remove(removeData, `anim-delay${rndDelay}`);
          const index = randomPool.indexOf(rndId);
          if (index !== -1) {
            randomPool.splice(index, 1);
          }

          randomAnime();
        }, 2500);
      }, 1500 * rndDelay);
    }
  };

  const animateMainVisual = () => {
    if (mainVisual) {
      randomAnime();

      setTimeout(() => {
        randomAnime();
      }, 500);
      setTimeout(() => {
        randomAnime();
      }, 1000);
      setTimeout(() => {
        randomAnime();
      }, 1500);
      setTimeout(() => {
        randomAnime();
      }, 2000);
    }
  };

  const setHeight = (detail, open = false) => {
    detail.open = open;
    const rect = detail.getBoundingClientRect();
    detail.dataset.width = rect.width;
    detail.style.setProperty(
      open ? '--expanded' : '--collapsed',
      `${rect.height}px`,
    );
  };

  const setDetailsHeight = (selector, wrapper = document) => {
    const details = wrapper.querySelectorAll(selector);
    const RO = new ResizeObserver((entries) => entries.forEach((entry) => {
      const detail = entry.target;
      const width = parseInt(detail.dataset.width, 10);
      if (width !== parseInt(entry.contentRect.width, 10)) {
        detail.removeAttribute('style');
        setHeight(detail);
        setHeight(detail, true);
        detail.open = false;
      }
    }));
    details.forEach((detail) => {
      RO.observe(detail);
    });
  };

  if (loader) {
    if (document.documentElement.classList.contains('video-played')) {
      animateMainVisual();
      loader.remove();
    } else {
      const video = loader.querySelector('video');
      video.loop = false;

      video.addEventListener('ended', () => {
        loader.classList.add('loaded');
        document.documentElement.classList.add('video-played');

        setTimeout(() => {
          animateMainVisual();
          loader.remove();
        }, 500);
      });
    }
  }

  if (document.querySelectorAll('details')) {
    setTimeout(() => {
      setDetailsHeight('details');
    }, 500);
  }
};
